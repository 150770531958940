/*
   Estilos globais para todas as aplicações são adicionados
   em seus respectivos arquivos que serão importados aqui
*/

//bootstrap vem em primeiro lugar pois nossos estilos devem ter
//prioridade na espeficidade do CSS
// @import '../node_modules/bootstrap/scss/bootstrap';
@import '~bootstrap/dist/css/bootstrap.css';

//Estilos base/resets
@import 'assets/css/_normalize/_normalize';

//Animate CSS
@import '~animate.css/animate.min';

//Datepicker
@import '~@angular/cdk/overlay-prebuilt.css';
@import 'assets/css/_modulos//datepicker';

//configurando fonte
@font-face {
  font-family: 'Rawline';
  src: url('assets/fonts/rawline-400.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Rawline';
  src: url('assets/fonts/rawline-700.ttf');
  font-weight: 700;
}

* {
  font-family: 'Rawline', sans-serif;
  font-weight: 400;
}

html, body {
  height: 100%;
  position: relative;
}

select {
  cursor: pointer;
}

my-app {
  min-height: 100%;
  display: block;
  position: relative;
  padding-bottom: 105px;
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.margem-top {
  margin-top: 80px;
}

.margem-bottom {
  margin-bottom: 80px;
}

.bold {
  font-weight: 700;
}

.mensagem-erro {
  font-size: 0.8rem;
  color: #E52207;
}

.mensagem-erro-negrito {
  font-size: 0.8rem;
  color: #E52207;
  font-weight: 700;
}

.swal2-confirm.swal2-styled {
  background-color: #1351B4;
}

.carregamento {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  text-align: center;
  background: rgba(22,22,22,0.5);
    img {
      max-height: 15%;
      max-width: 15%;
      width: auto;
      height: auto;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
}

.br-wizard {
  min-height: 100px !important;
}

.br-wizard .wizard-progress {
  background-color: white !important
}

.wizard-progress-btn {
  font-size: 0.9rem !important;
}

.wizard-font-s {
  font-size: 0.8rem !important;
}

.dp-picker-input {
  border-color: #888888;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  color: #333333;
  display: block;
  height: 40px !important;
  width: 100% !important;
  bottom: 0px;
  box-sizing: none !important;
  font-size: 1rem !important;
  margin-top: 4px;
  padding-left: 16px;
  padding-right: 16px;
}

.br-modal.x-large {
  min-width: 60%
}

.br-modal.xx-large {
  min-width: 80%
}

.p-dropdown {
  border-color: #888888 !important;
  padding-left: 0.75rem
}

button.small {
  background-color: #fff !important;
  border: none;
}


/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

// Modo Escuro
.modo-escuro,
.modo-escuro body,
.modo-escuro div.footer-area,
.modo-escuro section.margem-top,
.modo-escuro section.servicos,
.modo-escuro .modoEscuro-duvidas{
  background-color: #000000; 
  color: #ffffff; 
}

.modo-escuro p,
.modo-escuro h1,
.modo-escuro h2,
.modo-escuro h3,
.modo-escuro h4,
.modo-escuro h5,
.modo-escuro label,
.modo-escuro a.pointer,
.modo-escuro .sidemenu a,
.modo-escuro div.table-title,
.modo-escuro input#placeholder,
.modo-escuro p.texto-suporte,
.modo-escuro div.header-title,
.modo-escuro span.profile-text,
.modo-escuro span.header-title,
.modo-escuro div.servicos-texto,
.modo-escuro p.descricao__texto,
.modo-escuro div.servicos-titulo,
.modo-escuro p.cards__texto-maior,
.modo-escuro div.servicos-item__texto,
.modo-escuro div.br-modal-header,
.modo-escuro .invalid-feedback,
.modo-escuro .alerta-solicitante,
.modo-escuro .p-dropdown-label{
  color: #ffffff !important;
  text-decoration: underline !important; 
}

.modo-escuro .br-header,
.modo-escuro div.sidemenu,
.modo-escuro button.closebt,
.modo-escuro div.br-item,
.modo-escuro div.br-list,
.modo-escuro div.cards__items,
.modo-escuro button.closebtn,
.modo-escuro .p-dropdown,
.modo-escuro .p-dropdown-label
{
  background-color: #000000 !important; 
}

.modo-escuro div.servicos-item,
.modo-escuro td,
.modo-escuro .dropdown-tamanho,
.modo-escuro select,
.modo-escuro .modoEscuroTxt{
  background-color: #000000; 
  color: #ffffff !important; 
  text-decoration: underline;
}

.modo-escuro .sidemenu a:hover,
.modo-escuro div.servicos-item:hover,
.modo-escuro div.cards__items:hover,
.modo-escuro .modoEscuroTxt:hover{
  background-color: #202020 !important; 
  text-decoration: underline;
}

.modo-escuro input::placeholder,
.modo-escuro span.info,
.modo-escuro .br-header .header-actions .header-functions .br-list{
  color: #ffffff;
  border: none !important;
}

.modo-escuro .container-paginacao button,
.modo-escuro .br-button.secondary,
.modo-escuro input#protocoloFiltro,
.modo-escuro .swal2-popup,
.modo-escuro .btn-primary,
.modo-escuro #tamanhoPagina,
.modo-escuro .br-wizard .wizard-progress-btn::before,
.modo-escuro .position-modal,
.modo-escuro .posicao-botao,
.modo-escuro .desistir,
.modo-escuro .btn-secondary,
.modo-escuro .modal_escuro,
.modo-escuro .br-input input,
.modo-escuro div.filtroEscuro,
.modo-escuro button.botao-modoEscuro,
.modo-escuro .swal2-confirm.swal2-styled,
.modo-escuro .modoEscuro,
.modo-escuro .responder__botao,
.modo-escuro .responder__botao-anexo {
  background-color: #000000 !important; 
  color: #ffffff !important; 
  border: solid 1px #ffffff !important;
}

.modo-escuro .br-wizard .wizard-progress {
  background-color: rgb(0, 0, 0) !important;
}

.modo-escuro th{
  font-weight: 900;
  background-color: #000000; 
  color: #ffffff; 
  border: solid 1px #ffffff;
}

.modo-escuro input.dp-picker-input{
  background-color: #000000 !important;
  border: solid 1px #fff !important;
  color: #fff !important;
}

.modo-escuro button.upload-button {
  color: #fff !important;
  border-color: #fff !important;
}

.modo-escuro button.header-modoEscuro,
.modo-escuro .br-breadcrumb .crumb a{
  color: #fff !important;
}

.modo-escuro button.small {
  background-color: #000000 !important;
  color: #fff !important;
  border: none;
}

.modo-escuro .imagem,
.modo-escuro .iconeNfe_img {
  filter: none !important;
}

.br-modal-body {
  overflow: hidden !important;
}



