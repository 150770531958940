.dp-calendar-nav-container {
  padding: 20px;
  border: none !important;
  font-weight: 600;
  color: #000;
}
.dp-calendar-wrapper {
  padding: 10px;
  border: none !important;
  .dp-weekdays,
  .dp-months-row,
  .dp-calendar-week {
    font-size: 12px;
    font-weight: 500;
    color: #000;
  }
  .dp-calendar-week {
    .dp-current-day .dp-current-month {
      border: 1px solid #CCCCCC;
      color: #000;
      // font-weight: 600;
    }
  }
}

.dp-nav-btns-container {
  .dp-calendar-nav .dp-calendar-nav-left::before {
    border-color: #ccc !important;
    color: #000;
  }
}

.dp-current-location-btn {
  display: none;
}